var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.$vuetify.breakpoint.mdAndUp && _vm.self.loaded)?_c('v-app-bar',{style:(_vm.$vuetify.theme.dark ? 'z-index: 9999;' : 'z-index: 9999;background-color:#fff'),attrs:{"id":"menu","app":"","clipped-right":"","clipped-left":"","elevate-on-scroll":""}},[_c('div',{attrs:{"id":"menuHeader"}},[_c('v-img',{staticClass:"ma-0 pa-0 logo",attrs:{"src":_vm.$vuetify.theme.dark
            ? require('@/assets/logo_toro_white.svg')
            : require('@/assets/logo_toro_black.svg'),"height":"60","max-width":"150","contain":""},on:{"click":function($event){return _vm.$router.push({ name: 'Dashboard' })}}}),_c('v-spacer'),_c('div',{staticClass:"pa-1",attrs:{"id":"bellButton"}},[_c('pcc-button-tooltip',{attrs:{"icon":"mdi-bell","position":"bottom"}})],1),_c('div',{staticClass:"pa-1",attrs:{"id":"lightDarkButton"}},[_c('pcc-button-tooltip',{attrs:{"icon":"mdi-theme-light-dark","position":"bottom","tooltip":"Dark Mode"},on:{"click":function($event){return _vm.darkMode()}}})],1),_c('v-chip',{staticClass:"mt-2 text--secondary",attrs:{"id":"userIcon","router":"","label":"","color":"transparent"},on:{"click":function($event){return _vm.$router.push({ name: 'My' })}}},[_c('img',{staticClass:"logo",attrs:{"src":_vm.state.user.thumbnail ? _vm.state.user.thumbnail : require('@/assets/thumbnail.jpg'),"width":"100%"}})]),_c('v-btn',{attrs:{"text":"","icon":"","tile":"","dense":""},on:{"click":function($event){return _vm.signOut()}}},[_c('v-icon',{staticClass:"mt-1"},[_vm._v("power_settings_new")])],1)],1),_c('div',{attrs:{"id":"menuBoxed"}},[_vm._l((_vm.state.routes),function(item){return [(!item.children)?_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.path !== 'my'),expression:"item.path !== 'my'"}],key:item.path,staticClass:"mt-2 pa-5 text--secondary",attrs:{"router":"","to":{ name: item.name },"label":"","color":"transparent"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.path == 'my' ? _vm.state.user.name : item.name)+" ")],1):_vm._e(),(item.children)?[_c('v-menu',{key:item.name,attrs:{"open-on-hover":true,"close-delay":"333","offset-y":"","nudge-width":"233","nudge-top":"-17","close-on-click":"","z-index":9999},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mt-2 pa-5 text--secondary",attrs:{"router":"","label":"","color":_vm.isChildSelected(item) ? '#1f8bc4' : 'transparent'}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_c('span',{style:({ color: _vm.isChildSelected(item) ? '#fff' : '' })},[_vm._v(_vm._s(item.name))])],1)]}}],null,true)},[_c('v-card',{staticClass:"ma-0 pa-0",attrs:{"rounded":""}},[_c('v-card-text',{staticClass:"ma-0 px-0 py-2"},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.children),function(children){return _c('v-list-item',{key:children.path,attrs:{"to":{ name: children.name },"color":"#1f8bc4"}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"},domProps:{"innerHTML":_vm._s(children.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{style:({
                          'font-weight': 'normal',
                          color: _vm.$vuetify.theme.dark ? 'rgb(180 180 180)' : 'rgb(45 45 45)',
                        }),domProps:{"textContent":_vm._s(children.title)}})],1)],1)}),1)],1)],1)],1)]:_vm._e()]})],2)]):(_vm.self.loaded)?_c('v-app-bar',{style:(_vm.$vuetify.theme.dark ? 'z-index: 9999;' : 'z-index: 9999;background-color:#fff'),attrs:{"id":"menuMobile","app":"","clipped-right":"","clipped-left":"","elevate-on-scroll":""}},[_c('v-img',{staticClass:"ma-0 pa-0",attrs:{"src":_vm.$vuetify.theme.dark
          ? require('@/assets/logo_toro_white.svg')
          : require('@/assets/logo_toro_black.svg'),"height":"50","max-width":"150","contain":""},on:{"click":function($event){return _vm.$router.push({ name: 'Dashboard' })}}}),_c('v-spacer'),_c('v-menu',{attrs:{"open-on-hover":"","top":"","offset-y":"","nudge-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("apps")])],1)]}}])},[_c('v-list',{attrs:{"id":"menuListMobile","dense":"","nav":""}},[_vm._l((_vm.state.reducedRoutes),function(item,i){return [(item.heading)?_c('v-row',{key:i,attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[(item.heading)?_c('v-subheader',[_vm._v(" "+_vm._s(item.heading)+" ")]):_vm._e()],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"small":"","text":""}},[_vm._v("edit")])],1)],1):(item.divider)?_c('v-divider',{key:i,staticClass:"my-2 mx-2",attrs:{"dark":""}}):_c('v-list-item',{key:i,attrs:{"router":"","to":{ name: item.name }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.path == 'my' ? _vm.state.user.name : item.name)+" ")])],1)],1)]})],2)],1)],1):_vm._e(),_c('v-main',{class:_vm.$vuetify.theme.dark ? 'background-gradient-dark' : 'background-gradient-light'},[(!_vm.self.loaded)?_c('v-container',{staticClass:"fill-height mx-auto",attrs:{"fluid":"","dark":""}},[_c('v-card',{staticClass:"transparent elevation-0 mx-auto",attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? 400 : 600}},[_c('v-img',{attrs:{"src":_vm.$vuetify.theme.dark
              ? require('@/assets/logo_toro_white.svg')
              : require('@/assets/logo_toro_black.svg'),"height":"150","contain":""}}),_c('v-card-actions',[_c('v-spacer'),_c('v-progress-circular',{attrs:{"indeterminate":"","width":"1","size":"100","color":"grey"}}),_c('v-spacer')],1)],1)],1):_vm._e(),_c('router-view',{directives:[{name:"show",rawName:"v-show",value:(_vm.self.loaded),expression:"self.loaded"}]})],1),_c('v-footer',{attrs:{"app":"","absolute":"","color":"transparent","elevation":"0"}},[_c('span',{staticClass:"caption text-capitalize font-weight-light ml-5"},[_vm._v(" "+_vm._s(new Date().getFullYear())+" © TORO Advertising ")]),_c('v-spacer'),_c('span',{staticClass:"caption text-capitalize font-weight-light mr-5"},[_vm._v(" "+_vm._s(_vm.state.user.currency)+" | "+_vm._s(_vm.date))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }